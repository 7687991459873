import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const finalTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={finalTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: finalTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `facebook:card`,
          content: `summary`,
        },
        {
          property: `facebook:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          property: `facebook:title`,
          content: finalTitle,
        },
        {
          property: `facebook:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://du-cheval-a-soi.com`,
        },
        {
          property: `og:site_name`,
          content: `du-cheval-a-soi.com`,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `fr`,
  meta: ["coaching","coaching de vie", "équicoaching", "équicoach","cheval","coach","accompagnement en présence du cheval",
  "Du cheval à soi","dévelopement personnel", "nancy", "theate", "nancy theate", "burn-out", "burnout", "burn-out cheval", "burnout cheval","dépression",
  "problèmes relationnels", "problème relationnel", "problèmes relationnels cheval", "problème relationnel cheval", "problèmes relationnels cheval", "problème relationnel cheval",
  "traumatismes", "traumatisme", "phobies", "phobie", "objectifs personnels ou professionnels", "objectif personnel ou professionnel", "objectifs personnels ou professionnels cheval", 
  "objectif personnel ou professionnel cheval", "objectifs personnels ou professionnels cheval", "objectif personnel ou professionnel cheval",
  "manque de confiance en soi", "confiance en soi", "affirmation de soi", "gestion des émotions", "émotions", "émotions cheval", "émotions chevaux",
  "séparation des parents", "divorce", "famille recomposée", "du cheval à soi", "du-cheval-a-soi", "du cheval a soi", "du-cheval-à-soi", "adulte", "adultes",
  "enfant", "enfants", "ado", "adolescent", "adolescents", "sexualité", "LGBT", "épanouissement", "bien être", "travail sur soi", "intelligence émotionnelle",
  "cheval miroir", "animal miroir"],
  description: `Je vous accompagne dans vos problèmes via l'équicoaching, méthode de dévelopement personnel, qui utilise le cheval comme miroir de vos émotions.`,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
